// collections.js
export const collectionNames = [
  "0Monarchs NFT",
  "Age of estates",
  "Artsei Frens",
  "BabySiba_Inu",
  "BenchWarmers",
  "BobeJumps",
  "Botters.v2",
  "Chalks on sei",
  "Conys",
  "Cool Mods",
  "Cuddle Critters",
  "Daring Dragonz 4444",
  "DarkSeid",
  "Dystopia",
  "Estate Legends",
  "Exosence",
  "Exploring Dragonz",
  "Fud Foxes",
  "Gensuki",
  "Hyper Pies",
  "Kamigami 999",
  "Katz",
  "Korp_Dog",
  "LA Sins",
  "Marshmallow",
  "NPSeis",
  "Naked Llama Syndicate",
  "Naughty Owls",
  "Newtons",
  "Nightmares",
  "Nova Portal",
  "Oxydo",
  "PeepOnSei",
  "Pepeiyans",
  "Pesky Paws",
  "PeskyPaws Full",
  "Pixells",
  "Pixies",
  "Poogy",
  "Prylens",
  "RabDot",
  "Rabbiiiit",
  "Rhapsody Raccoons",
  "Runes",
  "SEI Dudes",
  "SEI PIXEL KING",
  "SEI Simians",
  "SEI Social",
  "SHARKY",
  "Sei Balloons",
  "Sei DROIDS",
  "Sei Faceless",
  "Sei Foxes",
  "Sei Goblins",
  "Sei Horse",
  "Sei Lama Club",
  "Sei Lands",
  "Sei Luca",
  "Sei OG",
  "Sei Sloths",
  "SeiBirbs",
  "SeiGMYM",
  "Seifari Explorers",
  "Seifari232",
  "Seigami",
  "Seilamis",
  "Seiletors",
  "Seitanists",
  "Seitich",
  "Seivians",
  "Seixy",
  "Sensei Turtles",
  "SentinelUndead",
  "Slimeys",
  "So Cubs",
  "Test_Cuddle",
  "The Farmors",
  "The Pamps",
  "The Raccoon Soseity",
  "Tiny Terminal",
  "Trek",
  "UnionSEI",
  "WAGMISEI",
  "WagmiSei (2)",
  "Wanderers",
  "What the Duck",
  "Wojak",
  "dob",
  "unkwn",
  "w00dy"
];
